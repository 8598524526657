import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const exportLabelDetails = async (base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }

    var pdfContent = []
    const date = new Date()
    const pageBreak = vm.length > 1 ? 'after' : ''

    vm.forEach(item => {
        var singlePos = [
            { qr: item.id.toString(), fit: '70', alignment: 'center', style:'qr' },
            { text: 'BAHOK COURIER LIMITED', style: 'poshead', alignment: 'center' },
            { text: item.merchant ? (item.merchant.business + ', '+ item.merchant.mobile) : '', style: 'merchant', alignment: 'justify' },

            { text: item.name + ', ' + item.mobile + (item.mobile_2 ? (' / ' + item.mobile_2) : ''), alignment: 'left', style: 'customer' },
            { text: item.address, alignment: 'justify', style: 'td' },
            { text: item.instruction ? 'Note: '+ item.instruction : '', alignment: 'justify', style: 'instruction' },
            { text: 'COD : TK ' + (item.price != 0 ? item.price : 'Paid') , alignment: 'center', style: 'instruction' },
            { text: (item.destination ? item.destination.name : '') + ' --> ' + item.area.name, alignment: 'center', style: 'cod' },
            { image: 'data:image/' + generateBarcode(item.id), alignment: 'center' },
            { text: "Bahok ID: "+ item.id + (item.exchange == 1 ? ' | Exchange' : ''), alignment: 'center', style: 'parcelID' },
            { text: 'Memon No: '+ (item.ref_id ? item.ref_id : '-') +', Date: '+ item.date, alignment: 'center', style: 'td' },
            { text: Store.state.authUser.name +' | www.bahokcourier.com | ' + date.toDateString() + ' at '+ date.toLocaleTimeString(), alignment: 'center', style: 'website', pageBreak: pageBreak }
        ]
        pdfContent.push(singlePos)
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: {
        width: 288, // 3 inch = 288 px
        height: 384 // 4 inch = 384 px
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      pageMargins: [ 10, 10, 10, 10],
      styles: {
        th: {
          fontSize: 10,
          margin: [0, 3, 3, 3]
        },
        td: {
          fontSize: 11,
          margin: [0, 2, 2, 0]
        },
        customer: {
          fontSize: 15,
          margin: [0, 2, 2, 0]
        },
        merchant: {
          fontSize: 15,
          margin: [0, 2, 2, 4]
        },
        qr: {
          margin: [5, 0, 0, 5]
        },
        cod: {
          fontSize:  10,
          margin: [2, 1, 0, 1]
        },
        instruction: {
          fontSize: 16,
          margin: [2, 1, 0, 1]
        },
        parcelID: {
          fontSize: 15,
          margin: [5, 0, 0, 5]
        },
        website: {
          fontSize: 9,
          italics: true,
          margin: [0, 0, 0, 8]
        },
        poshead: {
          fontSize: 8,
          margin: [5, 0, 0, 0]
        }
      }
    }
    var win = window.open('', '_blank')
    pdfMake.createPdf(docDefinition).open({}, win)
    // pdfMake.createPdf(docDefinition).print()
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function generateBarcode (orderNo) {
  let canvas = document.createElement('canvas');
  JsBarcode(canvas, orderNo, { 
      format: 'CODE39',
      displayValue: false,
      height: 22,
      width: 1.30,
      padding: 0,
      margin: 0
  });
  return canvas.toDataURL('image/png');
}

export default {
    exportLabelDetails
}
